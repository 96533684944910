import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import useUser from "../../../hooks/useUser";
import { Navigate } from "react-router-dom";

import MailOutlineIcon from "@mui/icons-material/MailOutline";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import AutoDeleteOutlinedIcon from '@mui/icons-material/AutoDeleteOutlined';
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';
import Typography from '@mui/material/Typography';

import { Store } from 'react-notifications-component';
import { Global } from "../../../helpers/Global";

export const Notificaciones = () => {
  const { jwt, getNotificacionesPendientes, notification, logout } = useUser();

  const [notificaciones, setNotificaciones] = useState();

  const [borrado, setBorrado] = useState(null);
  const [marcada, setMarcada] = useState(null);

  const ENDPOINT = "https://backend.biosecuritytrack.com/api/v1/notificaciones";

  const listNotificaciones = (jwt) => {
    return fetch(`${ENDPOINT}`, {
      method: "GET",
      headers: { Authorization: "Bearer " + jwt },
    })
      .then((res) => {
        const data = res.json();
        if (res.status === 401) {
          return res.status;
        }
        return data;
      })
      .then((res) => {
        if (res === 401) {
          Store.addNotification({
            ...notification,
            id: " ",
            title: "Ha habido un error",
            message: "Error de autenticación. Por favor vuelva a iniciar sesión.",
            type: "danger",
          });
          logout();
          return res;
        } else {
          setNotificaciones(res.data);
          return res.data;
        }
      });
  };

  useEffect(() => {
    listNotificaciones(jwt);
    getNotificacionesPendientes();
  }, [borrado, marcada]);

  const eliminarNotificacion = async (idNotificacion) => {

    const request = await fetch(Global.url + "notificaciones/delete/" + idNotificacion, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + jwt
      }
    });
    
    const datos = await request.json();

    if (datos.status === 201 ) {
      Store.addNotification({
        ...notification,
        id: " ",
        title: "Notificación eliminada",
        message: "Se ha eliminado la notificación correctamente.",
        type: "success",
      });
      setBorrado(idNotificacion)
    } else {
      Store.addNotification({
        ...notification,
        id: " ",
        title: "Ha habido un error",
        message: "No se ha podido borrar la notificación. Por favor vueva a intentarlo.",
        type: "danger",
      });
      setBorrado(idNotificacion)
    }
  };

  const borrarNotificacion = (e, idNotificacion) => {
    e.preventDefault();
    if(window.confirm("Vas a borrar la notificación, " + idNotificacion +"¿Quieres continuar?")){
      eliminarNotificacion(idNotificacion)
    }
  }

  const eliminarTodas = async (e) => {
    e.preventDefault();
    if(window.confirm("Vas a borrar todas las notificaciones de tu perfil. ¿Quieres continuar?")){
      
      const request = await fetch(Global.url + "notificaciones_borrar", {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + jwt
        }
      });
      
      const datos = await request.json();
  
      if (datos.status === 201 ) {
        Store.addNotification({
          ...notification,
          id: " ",
          title: "Notificaciones eliminadas",
          message: "Se han eliminado todas las notificaciones.",
          type: "success",
        });
        setBorrado(datos.status)
      } else {
        Store.addNotification({
          ...notification,
          id: " ",
          title: "Ha habido un error",
          message: "No se han podido borrar las notificaciones. Por favor vueva a intentarlo.",
          type: "danger",
        });
      }
    }
  }

  const leidasTodas = async (e) => {
    e.preventDefault();
    if(window.confirm("Vas a marcar como leídas todas las notificaciones de tu perfil. ¿Quieres continuar?")){
      
      const request = await fetch(Global.url + "notificaciones_leidas", {
        method: "GET",
        headers: {
          Authorization: "Bearer " + jwt
        }
      });
      
      const datos = await request.json();
  
      if (datos.status === 201 ) {
        Store.addNotification({
          ...notification,
          id: " ",
          title: "Notificaciones leidas",
          message: "Se han marcado como leídas todas las notificaciones.",
          type: "success",
        });
       setBorrado(datos.status)
      } else {
        Store.addNotification({
          ...notification,
          id: " ",
          title: "Ha habido un error",
          message: "Ha habido un error. Por favor vuelva a intentarlo.",
          type: "danger",
        });
       
      }
    }
  }

  const marcarNotificacionLeida = async (idNotificacion) => {

    const request = await fetch(Global.url + "notificacion_leida/" + idNotificacion, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + jwt
      }
    });
    
    const datos = await request.json();

    if(datos.status === 201 ) {
      Store.addNotification({
        ...notification,
        id: " ",
        title: "Notificación leída",
        message: "Notificación marcada como leída correctamente",
        type: "success",
      });
      setMarcada(idNotificacion);
    } else {
      Store.addNotification({
        ...notification,
        id: " ",
        tytle: "Error al marcar leída",
        message: "Ha habido un error. Por favor vuelva a intentarlo.",
        type: "danger",
      })
      setMarcada(idNotificacion);
    }
  }

  const marcarLeida = (e, idNotificacion) => {
    e.preventDefault();
    if(window.confirm("Vas a marcar la notificación cómo leida, ¿Quieres continuar?")){
      marcarNotificacionLeida(idNotificacion);
    }
  }

  return (
    <div className="container-title">
     
     { !jwt ? <Navigate to="/login" /> : '' }

     <h5><span className="spanTitulo">Notificaciones: </span></h5>

     
     <div className="col-md-12"> 
            <div className="form-group form-actions">
            <button 
              type="submit" 
              className="notificationIconButton" 
              onClick={(e) => {leidasTodas(e)}}
            ><MarkEmailReadOutlinedIcon color="success" />
                Marcar como leídas
            </button>

            <button 
              type="submit"
              className="notificationIconButton"  
              onClick={(e) => {eliminarTodas(e)}}
              ><AutoDeleteOutlinedIcon color="error"  />
                Eliminar todas
            </button>

        </div>
        </div> 
      

      {notificaciones &&
        notificaciones.map((notificacion, index) => {

          let accionNotificacion = "";
          if (notificacion.accion ==="crear_nave") {
            accionNotificacion = ' ha creado la nave';
          } else if (notificacion.accion === "eliminar_nave") {
            accionNotificacion = ' ha eliminado la nave';
          } else if (notificacion.accion === "modificar_nave") {
            accionNotificacion = ' ha actualizado la nave';
          } else if (notificacion.accion === "crear_nota" ) {
            accionNotificacion = ' ha creado la nota'
          } else if (notificacion.accion === "confirmar_formulario"){
            accionNotificacion = ' ha confirmado el formulario'
          } else if( notificacion.accion === 'crear_tarea') {
            accionNotificacion = ' ha creado la tarea '
          }

          let nombreNotificacion = notificacion.usuario_creador + accionNotificacion + " en la granja " +notificacion.granja;

          return (
            <List
              sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
              key={index}
              className={`notificacion ${notificacion.leido === '0'  ? 'notificacionPendiente' : ''}`}
            >
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <MailOutlineIcon />
                    </Avatar>
                  </ListItemAvatar>
                    <ListItemText className="nombreNotificacion" primary={nombreNotificacion} secondary={notificacion.fecha_formato} />       
                </ListItem>
                <ListItemButton>
                  <div className="notification-action">
                  <ListItemIcon>
                    <div className="notification-icons">
                    {notificacion.leido === '0' ? <button  className="notificationIconButton" onClick={(e) => {marcarLeida(e, notificacion.id)} }><MarkEmailReadOutlinedIcon color="success" /></button> : ''} 
                    <button className="notificationIconButton" onClick={(e) => {borrarNotificacion(e, notificacion.id)}} type="submit"><AutoDeleteOutlinedIcon color="error"  /></button>
                    </div>
                    {notificacion.accion === "eliminar_nave" ? ('') : (<div className="notification-enlace"><Link to={"/"+notificacion.url+"/"+notificacion.id_tabla}><ForwardToInboxIcon /> Ir a notificación</Link></div>)}
                  </ListItemIcon>
                  </div>
                </ListItemButton>
            </List>
          );
        })}
    </div>

  );
};
